/* main */

* {
  margin: 0;
  padding: 0;
}

/* Navbar */

.title {
  float: none;
  margin: 0 auto;
}

.navbar-title {
  color: white;
  font-size: 16px;
}

.actived {
  color: rgba(0, 110, 212, 0.747);
}

.bottom-btn {
  border: none;
}

.offcanvas-footer {
  padding: 16px;
}

.language-title {
  line-height: 21.86px;
}

.language-button {
  background-color: #f6f6f6;
  border-radius: 4px;
  padding: 4px;
}

.selected-language {
  padding: 10px 0px 10px 0px;
  border-radius: 4px;
  background-color: #303030;
  color: white;
  font-size: 14px;
}

.default-language-button {
  color: #606060;
  line-height: 19.12px;
  font-size: 14px;
}

.note {
  font-size: 12px;
  line-height: 16.39px;
  margin-top: 5px;
  color: #606060;
}

@media screen and (min-width: 1024px) {
  .btn {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 320px) {
  .selected-language,
  .default-language-button {
    font-size: 12px;
  }
}

.btn.disabled {
  background-color: #ccc;
  border: #ccc;
  color: #888;
  cursor: not-allowed;
}

.title-margin {
  margin-right: 10px;
}
