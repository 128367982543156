.error:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.6);
}

.backHomePage {
  color: #3fbaff;
  text-decoration: underline;
  cursor: pointer;
}

.font-14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  letter-spacing: 0.04em;
  text-align: left;
}

.font-12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.39px;
  letter-spacing: 0.04em;
  text-align: left;
}

.color-606060 {
  color: #606060;
}
.color-909090 {
  color: #909090;
}
.color-3FBAFF {
  color: #3fbaff;
}

.border-bottom-gray {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
}

.font-16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.86px;
  letter-spacing: 0.04em;
  text-align: left;
}

.warning-bank {
  background: rgba(255, 207, 68, 0.3);
}
