.title2 {
  font-size: 14px;
  font-weight: 400;
}

.titletnc {
  font-size: 12px;
  font-weight: 400;
}

.titletncColor {
  font-size: 12px;
  font-weight: 400;
  color: #3fbaff;
  cursor: pointer;
}

.guestText {
  font-size: 14px;
  font-weight: 400;
  color: #3fbaff;
  text-decoration: underline;
}

.signupTitle {
  font-size: 14px;
  font-weight: 500;
}

.signupTitle1 {
  font-size: 12px;
  font-weight: 400;
  color: #858585;
}

.text-12 {
  font-size: 12px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mailIcon {
  padding: 22px;
  gap: 10px;
  width: 94px;
  height: 94px;
  background: rgba(106, 184, 255, 0.79);
  border-radius: 50px;
}

.cstmbtn.enabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 100%;
  height: 43px;
  background: #282828;
  color: #ffffff;
}

.cstmbtn.disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 100%;
  height: 43px;
  background: #f5f5f5;
  color: #727070;
  border: #f5f5f5;
  cursor: not-allowed;
}

.inputs input {
  width: 40px;
  height: 40px;
}

.resendOTP {
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  color: #3fbaff;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
