.receipt-container {
  padding-top: 16px;
  padding-bottom: 16px;
  flex-grow: 1;
}

.payment-status-container {
  background-color: white;
  padding: 16px;
  text-align: center;
  margin-bottom: 26px;
}

.payment-status-title {
  color: #e15241;
  line-height: 27.32px;
  font-size: 20px;
  font-weight: 500;
  margin-top: 16px;
}

.payment-status-description {
  color: #606060;
  line-height: 19.12px;
  font-size: 14px;
  white-space: pre-wrap;
}

.payment-status-button {
  border: 1px #606060 solid;
  background-color: transparent;
  padding: 12px 16px 12px 16px;
  width: "100%";
  margin-top: 30px;
}

.payment-receipt-container {
  background-color: white;
  padding: 16px;
  position: relative;
  overflow: hidden;
}

.receipt-payment-amount {
  line-height: 32.78px;
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 0px;
}

.order-number {
  color: #909090;
  font-size: 10px;
  line-height: 13.66px;
  margin-bottom: 5px;
}

.order-name {
  font-size: 14px;
  line-height: 19.12px;
  color: "#606060";
}

.order-container {
  border-bottom: 1px dashed #dcdee0;
  margin-bottom: 10px;
}

.order-remark {
  font-size: 12px;
  line-height: 16.39px;
  color: #909090;
  margin-left: 5px;
}

.kocek-amount-title {
  line-height: 18px;
  color: #606060;
  font-size: 12px;
  margin-bottom: 5px;
}

.subtotal {
  color: #909090;
  font-size: 12px;
  line-height: 18px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.kocek-amount {
  font-size: 12px;
  line-height: 16.39px;
  font-weight: 500;
  color: #606060;
  margin-bottom: 5px;
}

.amount-due {
  color: #303030;
  font-size: 14px;
  line-height: 18px;
}

.circles {
  position: absolute;
  bottom: -10px;
  left: 0px;
  width: 100%;
}

.user-details-container {
  background-color: white;
  padding: 16px;
  margin-top: 26px;
}

.details-title {
  color: #909090;
  font-size: 10px;
  line-height: 13.66px;
  margin-bottom: 5px;
}

.details-1 {
  color: #303030;
  font-size: 14px;
  line-height: 19.12px;
  margin-bottom: 2px;
}

.details-2 {
  color: #606060;
  font-size: 12px;
  line-height: 16.39px;
  margin-bottom: 2px;
}

.personal-details {
  border-bottom: 1px #cccccc solid;
  padding-bottom: 16px;
}

.order-instruction {
  padding: 16px;
  background-color: white;
  margin-top: 26px;
}

.order-instruction-list-container {
  padding-left: 16px;
}

.download-receipt-button {
  color: white;
  border: 1px white solid;
  margin-top: 26px;
  text-align: center;
  padding: 12px 16px 12px 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19.12px;
  text-decoration-line: none;
}

.copyright {
  color: white;
  font-size: 12px;
  line-height: 16.39px;
  text-align: center;
  margin-top: 26px;
  margin-bottom: 10px;
}

@media screen and (max-width: 319px) {
  .kocek-amount-title {
    font-size: 10px;
  }
  .subtotal {
    font-size: 10px;
  }
  .amount-due {
    font-size: 12px;
  }
}
