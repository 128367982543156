.flex-comtainer {
  width: 50%;
  height: auto;
}

.flex-box {
  width: auto;
  height: auto;
  margin: 10px;
  border: 1px;
}

.card-active {
  border: 1px solid #0b6fe2;
}

.card-merchant {
  font-size: 14px;
  font-weight: 500;
}

.card-details {
  font-size: 12px;
  font-weight: 400;
}

.main-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 19.12px;
  color: #909090;
}

.tagline {
  font-weight: 400;
  font-size: 14px;
  line-height: 19.12px;
  color: #606060;
}

.paginationNo {
  padding: 0 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

.button-chevron {
  padding: 0 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.btn.disable {
  pointer-events: none;
  opacity: 0.2;
}

.tar-left {
  float: left;
}
.tar-right {
  float: right;
}

.dot {
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: black;
  margin: 2px 5px; /* Adjust the margin as needed */
}

.btnFilter {
  background-color: #eeeeee;
  /* border-color: #303030; */
  padding: 3px 7px;
  border: 1px;
  /* color: #303030; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  cursor: pointer;
}

.cstmbdy {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.cnfrmbtn {
  background-color: #050505; /* Replace with the desired background color for the footer */
  padding: 10px; /* Adjust the padding as needed */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 10px;
}

.filter-title {
  padding-top: 16px;
  /* padding-bottom: 16px; */
  padding-left: 16px;
}

.filter-bar {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 16px; /* Gap between columns */
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

/* Left buttons container */
.left-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Right buttons container */
.right-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Style for buttons */
.button {
  width: 100%;
  padding: 5px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #e2e1e1;
  font-size: 14px;
  font-weight: 400;
}

.selectedTime {
  border-color: #303030; /* Change border color when clicked */
}

.cnfrmbtnModal {
  background-color: #050505; /* Replace with the desired background color for the footer */
  padding: 10px; /* Adjust the padding as needed */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #ffffff;
}

.back-bottom-sheet {
  position: absolute;
  left: 15px;
}

.text-reset {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.04em;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .App-logo {
    width: 20%;
    margin-left: auto;
    margin-right: auto;
  }
}
