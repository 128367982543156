.font-14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  letter-spacing: 0.04em;
  text-align: left;
}

.font-12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.39px;
  letter-spacing: 0.04em;
  text-align: left;
}

.font-weight-bold {
  font-weight: 500;
}
.color-606060 {
  color: #606060;
}
.color-909090 {
  color: #909090;
}
.color-14A44D {
  color: #14a44d;
}

.transaction-summary {
  font-size: 14px;
}

.border-top-1 {
  border-top: 1px solid #e0e0e0;
  padding-top: 0px;
}

.border-bottom-1 {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
}

.padding-l-20 {
  padding-left: 20px;
}

.cstmbtn.enabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 100%;
  height: 43px;
  background: #282828;
  color: #ffffff;
}

.cstmbtn.disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 100%;
  height: 43px;
  background: #f5f5f5;
  color: #727070;
  border: #f5f5f5;
  cursor: not-allowed;
}

.font-16 {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.86px;
  letter-spacing: 0.04em;
  text-align: left;
}
