.icon-location {
  font-size: 14px;
  padding-right: 16px;
}

.address-details {
  border-bottom: 1px solid #e0e0e0;
}

.merchant-detail {
  font-size: 14px;
  color: #303030;
}

.opening-hours {
  color: #39b55d;
}

.font-weight-bold {
  font-weight: 500;
}
.color-606060 {
  color: #606060;
}

.modal-bottom {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  min-width: 100vw;
  height: 375px;
  margin: 0;
  transition: transform 0.3s ease-out;
}

.modal-bottom .modal-content {
  height: 100%;
  border-radius: 0;
  width: 100%;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.cstmbtn.enabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 100%;
  height: 43px;
  background: #282828;
  color: #ffffff;
}

.cstmbtn.disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 100%;
  height: 43px;
  background: #f5f5f5;
  color: #727070;
  border: #f5f5f5;
  cursor: not-allowed;
}

.border-color-282828 {
  border: 1px solid #282828;
}

.border-color-909090 {
  border: 1px solid rgba(144, 144, 144, 0.15);
}
