.checkout-container {
  padding: 16px;
  background-color: white;
}

.coin-container {
  padding-left: 16px;
  padding-right: 16px;
}

.order-summary {
  background-color: white;
  padding-top: 16px;
  padding-bottom: 16px;
}

.title {
  font-size: 12px;
  font-weight: 900;
  color: #606060;
  line-height: 16px;
  margin-bottom: 8px;
}

.title2 {
  font-weight: 400;
  font-size: 12px;
  color: #909090;
  margin-bottom: 3.5px;
}

.booth-container {
  margin-left: 11.75px;
}

.location-name {
  font-size: 14px;
  color: #303030;
  margin-bottom: 0px;
}

.details-container {
  margin-top: 12px;
}

.order-summary-container {
  margin-top: 8px;
  margin-bottom: 8px;
}

.quantity-container {
  height: fit-content;
  width: fit-content;
}

.edit-quantity {
  min-width: 35px;
  font-size: 12px;
  font-weight: 900;
  color: #606060;
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 21px;
}

.coin-name {
  font-size: 14px;
}

.coin-min {
  font-size: 12px;
  color: #606060;
}

.order-coin-status-old {
  font-size: 10px;
  background-color: #909090;
  color: white;
  margin-bottom: 2px;
  margin-left: 10px;
  padding: 4px;
  font-weight: 700;
}

.order-coin-status-new {
  font-size: 10px;
  background-color: #a18a68;
  color: white;
  margin-bottom: 2px;
  margin-left: 10px;
  padding: 4px;
  font-weight: 700;
}

.coin-amount {
  color: #303030;
  font-size: 14px;
}

.order-row-container {
  position: relative;
  padding: 16px;
  width: 100%;
  margin-left: 0px;
}

.amount-container {
  margin-top: 16px;
}

.coin-amount {
  font-size: 14px;
  font-weight: 600;
}

.pickup {
  margin: 0px;
  margin-top: 2px;
  font-size: 12px;
  color: #606060;
}

.add-minus-quantity-container {
  height: fit-content;
  width: fit-content;
  position: absolute;
  left: 80px;
  top: 16px;
  background-color: white;
}

.add-minus {
  padding: 12px;
}

.fade-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}

.payment-title {
  font-size: 14px;
  color: #3fbaff;
  line-height: 21.86px;
}

.selected-payment-title {
  font-size: 14px;
  color: #606060;
  line-height: 21.86px;
}

.subtitle {
  font-size: 14px;
  color: #303030;
  line-height: 19.12px;
  margin-bottom: 13px;
}

.total-payment {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.86px;
  color: #303030;
}

.left-close-sheet {
  position: absolute;
  left: 21.42px;
}

.right-close-sheet {
  position: absolute;
  right: 21.42px;
}

.subtitle {
  color: #606060;
  font-size: 14px;
}

.tnc-title {
  font-size: 14px;
  line-height: 19.12px;
}

.content-container {
  border: 1px solid #f5f5f5;
  padding: 16px;
  margin-bottom: 12px;
}

.content {
  margin-bottom: 0px;
  color: #606060;
  line-height: 16.39px;
  font-size: 12px;
  text-align: justify;
}

.emphasis {
  border: #3fbaff solid 1px;
  background-color: #f0f9ff;
}

@media screen and (max-width: 350px) {
  .edit-quantity {
    min-width: 20px;
    font-size: 10px;
  }
  .coin-name {
    font-size: 12px;
  }

  .coin-min {
    font-size: 10px;
  }
  .order-coin-status-old {
    font-size: 8px;
  }

  .order-coin-status-new {
    font-size: 8px;
  }
  .coin-amount {
    font-size: 12px;
  }
  .pickup {
    font-size: 10px;
  }
  .add-minus-quantity-container {
    left: 60px;
  }
  .add-minus {
    padding: 10px;
  }
  .subtitle {
    font-size: 12px;
  }
  .title2 {
    font-size: 10px;
  }
  .edit-quantity {
    padding: 0;
  }
  .payment-title {
    font-size: 12px;
  }
  .selected-payment-title {
    font-size: 12px;
  }
  .quantity {
    font-size: 12px;
  }
}
