.radio {
  border: 1px solid #dddddd;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  padding: 3px;
}

.radio-checked {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}
