.kocek-sign-up {
  color: #606060;

  .create-account {
    color: #3fbaff;
    text-decoration: underline;
    cursor: pointer;
  }
}

.error:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.6);
}

.steps-title {
  color: #909090;
  font-weight: 500;
}

.backHomePage {
  color: #3fbaff;
  text-decoration: underline;
  cursor: pointer;
}
