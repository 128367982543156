.payment-method-container {
  padding-top: 16px;
  padding-bottom: 16px;
}

.payment-method {
  font-size: 12px;
  color: #909090;
  line-height: 16.2px;
}

.ktm-ps {
  font-size: 12px;
  color: #606060;
  line-height: 16.2px;
  font-weight: 500;
  margin-bottom: 5px;
}

.ktm {
  font-weight: 500;
  font-size: 14px;
  line-height: 19.12px;
  color: #303030;
  margin-top: 32px;
  margin-bottom: 0px;
}

.payment-instruction {
  color: #606060;
  font-size: 12px;
  line-height: 16.39px;
}
