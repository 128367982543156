.payment-instructions-container {
  background-color: white;
  padding: 16px;
}

.jompay-title {
  line-height: 19.12px;
  font-weight: 500;
  font-size: 14px;
  color: #303030;
  margin-top: 16px;
  margin-bottom: 0px;
}

.jompay-subtitle {
  line-height: 16.39px;
  font-size: 12px;
  color: #606060;
  margin-top: 5px;
  margin-bottom: 0px;
}

.instructions {
  margin-top: 16px;
}

.list {
  font-size: 14px;
  line-height: 19.6px;
  color: #303030;
  margin-bottom: 8px;
  padding-left: 8px;
}

.highlighted-text {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #dddddd;
  width: fit-content;
  margin-top: 4px;
}
