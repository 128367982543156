.business-title {
  font-size: 14px;
  color: #606060;
  line-height: 19.12px;
  margin-bottom: 16px;
  font-weight: 400;
}

.business-subtitle {
  font-size: 16px;
  line-height: 24px;
  color: #303030;
}

.dropdown-selection {
  padding: 16px;
}

.search-icon {
  margin-right: 10px;
}

.search-bar {
  background: linear-gradient(0deg, #eeeeee, #eeeeee), linear-gradient(0deg, #fbfbfb, #fbfbfb);
  border: 1px solid #eeeeee;
}

.search-input {
  background-color: transparent;
}

.search-input:focus {
  outline: none;
}

.industry {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.industry-name {
  margin-bottom: 0;
  font-size: 14px;
  color: #606060;
  line-height: 19.12px;
}

::placeholder {
  color: #909090;
  font-size: 16px;
  line-height: 24px;
}

.optional {
  color: #909090;
  line-height: 24px;
}

[data-rsbs-header] {
  box-shadow: none;
}

.main-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 19.12px;
  color: #909090;
}

@media screen and (max-width: 320px) {
  .industry-name {
    font-size: 12px;
  }

  .search-input {
    font-size: 14px;
  }

  ::placeholder {
    font-size: 14px;
  }

  .business-subtitle {
    font-size: 14px;
  }
  .industry-dropdown {
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) {
  .industry-bottom-sheet {
    --rsbs-max-w: 50%;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
  }
}
