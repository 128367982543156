.total-title {
  color: #909090;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
}

.fa-chevron-up {
  color: #3a86ff;
}

.fa-chevron-down {
  color: #3a86ff;
}

.place-order-button-text {
  color: white;
  font-weight: 700;
  font-size: 16;
}

.container {
  margin-bottom: 100px;
}

.coin-name {
  font-size: 16px;
  margin-bottom: 2px;
}

.coin-min {
  font-size: 14px;
  color: #606060;
  margin-bottom: 2px;
}

.coin-status {
  font-size: 14px;
  color: #909090;
  margin-bottom: 2px;
}

.order-coin-status-old {
  font-size: 10px;
  background-color: #909090;
  color: white;
  margin-bottom: 2px;
  margin-left: 10px;
  padding: 4px;
  width: fit-content;
  font-weight: 700;
}

.order-coin-status-new {
  font-size: 10px;
  background-color: #a18a68;
  color: white;
  margin-bottom: 2px;
  margin-left: 10px;
  padding: 4px;
  width: fit-content;
  font-weight: 700;
}

.total-coin-amount {
  font-size: 14px;
  line-height: 19.12px;
  color: #303030;
}

.quantity {
  width: 50%;
  background-color: white;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  line-height: 21px;
}

.quantity:focus {
  outline: none;
}

.add-delete-quantity {
  padding-top: 4px;
  padding-bottom: 4px;
  width: 25%;
  font-size: 14px;
  text-align: center;
  background-color: white;
  line-height: 21px;
}

.close-bottom-sheet {
  position: absolute;
  right: 15px;
}

.order-details-container {
  margin-bottom: 0px;
}

.empty-order-container {
  margin-top: 32px;
  color: #909090;
  font-size: 14px;
  margin-bottom: 32px;
}

.order-quantity {
  font-size: 10px;
  padding: 9px;
  width: 100%;
  height: 30px;
  font-weight: 700;
}

.total-container {
  margin-top: 16px;
}

.total-items {
  font-size: 16px;
  line-height: 19px;
}

.total-amount {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0px;
}

.service-fee {
  color: #606060;
  font-size: 14px;
  line-height: 17px;
}

.limit-message {
  font-size: 10px;
}

.add-delete-symbol {
  color: #3a86ff;
}

.subtitle {
  color: #606060;
  font-size: 14px;
}

.row-disabled {
  opacity: 0.3;
}

.subtotal-amount {
  font-size: 16px;
}

[data-rsbs-header]::before {
  height: 0;
}

.main-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 19.12px;
  color: #909090;
}

.out-of-stock-container {
  background-color: #fafafa;
  opacity: 0.4;
}

.out-of-stock-div {
  background-color: #f9d42c;
  padding: 4px 8px 4px 8px;
  width: 100%;
  text-align: center;
}

.out-of-stock {
  font-size: 10px;
  margin-bottom: 0px;
}

@media screen and (max-width: 320px) {
  .coin-name {
    font-size: 14px;
  }

  .coin-min {
    font-size: 12px;
  }

  .coin-status {
    font-size: 12px;
  }
  .quantity-column {
    padding: 0;
  }

  .place-order-button-text {
    font-size: 14px;
  }

  .subtotal-amount {
    font-size: 14px;
  }
  .total-coin-amount {
    font-size: 12px;
  }
  .total-items {
    font-size: 14px;
  }
  .total-amount {
    font-size: 14px;
  }
  .service-fee {
    font-size: 12px;
  }
  .order-coin-status-old {
    font-size: 7px;
  }
  .order-coin-status-new {
    font-size: 7px;
  }
}

@media screen and (max-width: 425px) and (min-width: 321px) {
  .quantity-column {
    padding: 0;
  }
  .order-coin-status-old {
    font-size: 8px;
  }
  .order-coin-status-new {
    font-size: 8px;
  }
}

@media screen and (max-width: 425px) and (min-width: 351px) {
  .out-of-stock {
    font-size: 8px;
  }
}

@media screen and (max-width: 350px) {
  .out-of-stock {
    font-size: 6px;
  }
}

@media screen and (min-width: 1024px) {
  .stock-bottom-sheet {
    --rsbs-max-w: 50%;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
  }
}

@media screen and (min-width: 990px) {
  .out-of-stock {
    font-size: 7px;
  }
}
