.service-fee-title {
  font-size: 14px;
  line-height: 19.12px;
  color: #606060;
  margin-top: 16px;
}

.input-amount-container {
  border-bottom: 1px solid #eeeeee;
}

.input-amount {
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
}

.calculated-amount-container {
  padding: 12px 21px 12px 21px;
  background-color: #ffcf444d;
  border-radius: 4px;
  margin-top: 16px;
}

.service-row {
  color: #606060;
  line-height: 18px;
  font-size: 14px;
}

.payment-row {
  font-size: 14px;
  line-height: 18px;
}

.stock-availability {
  font-size: 12px;
  line-height: 18px;
  color: #606060;
}

.amount {
  line-height: 24px;
  font-size: 16px;
}
